"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const action_1 = require("@src/app/services/action");
const defaultOptions = {
    amount: undefined,
    from: undefined,
    to: undefined,
    // amount: 10,
    // from: -999999999,
    // to: 999999999,
    rawValue: undefined,
    order: null,
    separator: 'space'
};
const separateMap = {
    space: ' ',
    comma: ', ',
    dot_comma: '; ',
    new_line: '\n',
};
const random = (min, max) => Math.floor(Math.random() * (max + 1 - min)) + min;
const randomRange = (min, max, length) => {
    let result = [];
    while (result.length <= length) {
        result.push(random(min, max));
    }
    return result;
};
const randomChoose = (choices, length) => {
    let result = [];
    while (result.length <= length) {
        const index = random(0, choices.length - 1);
        result.push(choices[index]);
    }
    return result;
};
class NumbergeneratorCtrl {
    constructor($scope, $timeout, $filter, ConfigService, actionService) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$filter = $filter;
        this.ConfigService = ConfigService;
        this.actionService = actionService;
        this.type = 'range';
        this.options = Object.assign(Object.assign(Object.assign({}, defaultOptions), JSON.parse(localStorage.getItem(`${this.constructor.name}_options`) || '{}')), {
            amount: undefined,
            from: undefined,
            to: undefined,
            rawValue: undefined,
        });
        this.maxIterateOffset = 1000;
    }
    $onInit() {
        this.$scope.$watch('$ctrl.options', (options) => {
            localStorage.setItem(`${this.constructor.name}_options`, JSON.stringify(options));
        }, true);
        this.$scope.$watch('$ctrl.options.separator', (separator) => {
            var _a;
            if ((_a = this.resultList) === null || _a === void 0 ? void 0 : _a.length) {
                this.result = this.resultList.join(separateMap[separator != undefined ? separator : 'space']);
            }
        });
        let initType = false;
        this.$scope.$watch('$ctrl.type', () => {
            if (initType)
                this.clear();
            else
                initType = true;
        });
    }
    newGame(type) {
        this.type = type;
    }
    orderBy(direct) {
        var _a;
        this.options.order = direct || null;
        if ((_a = this.resultList) === null || _a === void 0 ? void 0 : _a.length) {
            let resultList = [...this.resultList];
            if (this.options.order == 'lower') {
                resultList.sort((a, b) => (a - b));
            }
            else if (this.options.order == 'upper') {
                resultList.sort((a, b) => (a - b)).reverse();
            }
            this.result = resultList.join(separateMap[this.options.separator]);
        }
    }
    separateBy(value) {
        this.options.separator = value || null;
    }
    rangeSubmit($form) {
        if ($form.$valid) {
            try {
                this.error = undefined;
                let result = [];
                let count = 0;
                if (this.options.from >= this.options.to) {
                    throw this.$filter('translate')('Minimum is more or equal with maximum');
                }
                else if ((this.options.to - this.options.from + 1) < this.options.amount) {
                    throw this.$filter('translate')('Range is too small');
                }
                else if (!this.ConfigService.cookieSettings.allow_repeats) {
                    while ((result.length < this.options.amount) && (count < this.maxIterateOffset)) {
                        count++;
                        let items = [...result, ...randomRange(this.options.from, this.options.to, this.options.amount)];
                        result = [...new Set(items)];
                    }
                }
                else {
                    while ((result.length < this.options.amount) && (count < this.maxIterateOffset)) {
                        count++;
                        result = [...result, ...randomRange(this.options.from, this.options.to, this.options.amount)];
                    }
                }
                if (count >= this.maxIterateOffset)
                    throw this.$filter('translate')('Infinite iterate');
                this.resultList = result.slice(0, this.options.amount);
                this.orderBy(this.options.order);
            }
            catch (e) {
                this.resultList = undefined;
                this.result = undefined;
                if (ng.isString(e))
                    this.error = e;
            }
        }
    }
    listSubmit($form) {
        if ($form.$valid) {
            const values = this.options.rawValue.split(/[\s\,\;\\n]/g).filter((value) => {
                return value != '';
            });
            try {
                this.error = undefined;
                let result = [];
                let count = 0;
                if (values.length <= 1) {
                    throw this.$filter('translate')('Range is one value');
                }
                else if (!this.ConfigService.cookieSettings.allow_repeats) {
                    if (values.length < this.options.amount) {
                        throw this.$filter('translate')('Range is too small');
                    }
                    else {
                        while ((result.length < this.options.amount) && (count < this.maxIterateOffset)) {
                            count++;
                            let items = [...result, ...randomChoose(values, this.options.amount)];
                            result = [...new Set(items)];
                        }
                    }
                }
                else {
                    while ((result.length < this.options.amount) && (count < this.maxIterateOffset)) {
                        count++;
                        result = [...result, ...randomChoose(values, this.options.amount)];
                    }
                }
                if (count >= this.maxIterateOffset)
                    throw this.$filter('translate')('Infinite iterate');
                this.resultList = result.slice(0, this.options.amount);
                this.orderBy(this.options.order);
            }
            catch (e) {
                this.resultList = undefined;
                this.result = undefined;
                if (ng.isString(e))
                    this.error = e;
            }
        }
    }
    copy() {
        this.actionService.copyFactory(document.getElementById('result'), this.result);
    }
    download() {
        this.actionService.downloadFactory('numbergenerator_zone', this.result);
    }
    clear() {
        var _a, _b;
        this.error = undefined;
        this.resultList = undefined;
        this.result = undefined;
        this.options = Object.assign(Object.assign({}, this.options), {
            from: undefined,
            to: undefined,
            amount: undefined,
            rawValue: undefined
        });
        if ((_a = this.rangeForm) === null || _a === void 0 ? void 0 : _a.$dirty) {
            this.rangeForm.$setPristine();
            this.rangeForm.$setUntouched();
        }
        if ((_b = this.listForm) === null || _b === void 0 ? void 0 : _b.$dirty) {
            this.listForm.$setPristine();
            this.listForm.$setUntouched();
        }
    }
}
NumbergeneratorCtrl.$inject = ['$scope', '$timeout', '$filter', 'ConfigService', 'ActionService'];
const appModule = ng.module('app');
appModule.service('ActionService', action_1.ActionService);
appModule.component('gameNumbergenerator', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: NumbergeneratorCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('numbergenerator/');
    }]);
